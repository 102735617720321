"use client";

import useSmoothAnchors from "@/components/pop/hooks/useSmoothAnchors";
import Footer from "@/components/pop/layout/footer";
import Header from "@/components/pop/layout/header";
import { Guide } from "@/components/pop/promotional/guide";
import { PromoTerms } from "@/components/pop/promotional/promo-terms";
import { FinancingDontSuckTextSVG } from "@/components/pop/promotional/svgs/financing-dont-suck.svg";
import { Button } from "@/components/ui/button";
import Image from "next/image";
import Link from "next/link";
function Hero() {
  return <div className="m-12 flex w-[345px] flex-row items-center justify-center rounded-xl bg-white bg-opacity-50 text-center shadow-[0px_0px_48px_rgba(0,_0,_0,_0.05)] [backdrop-filter:blur(32px)] sm:w-[384px] md:m-[120px] md:h-[409px] md:w-[748px] lg:h-[522px] lg:w-[936px]" data-sentry-component="Hero" data-sentry-source-file="page.tsx">
      <div className="relative flex h-full w-full gap-6 px-6 md:flex-row lg:gap-12 lg:px-12">
        <div className="flex h-full w-[297px] flex-1 flex-col justify-between gap-6 py-6 sm:w-[336px] lg:w-[400px] lg:gap-12 lg:py-12">
          <FinancingDontSuckTextSVG data-sentry-element="FinancingDontSuckTextSVG" data-sentry-source-file="page.tsx" />
          <div className="flex">
            <Button className="h-12 flex-1 rounded-xl text-base text-white" variant={"gradient"} asChild data-sentry-element="Button" data-sentry-source-file="page.tsx">
              <Link href="/invitations/code" data-sentry-element="Link" data-sentry-source-file="page.tsx">Claim Your Offer</Link>
            </Button>
          </div>
        </div>
        <div className="relative hidden h-full w-[298px] flex-1 items-center justify-center md:flex lg:w-[387px]">
          <Image src={"/assets/hero-promo-campaign.png"} alt={"Promo Campaign"} width={387} height={498} className="absolute bottom-0 h-[385px] w-[298px] lg:h-[498px] lg:w-[387px]" data-sentry-element="Image" data-sentry-source-file="page.tsx" />
        </div>
      </div>
    </div>;
}
export default function Page() {
  useSmoothAnchors();
  return <div className="flex flex-1 flex-col items-center" data-sentry-component="Page" data-sentry-source-file="page.tsx">
      <div className="flex w-full max-w-[1440px] flex-1 flex-col">
        <Header className="border-[0px]" data-sentry-element="Header" data-sentry-source-file="page.tsx">
          <div className="hidden flex-row items-center justify-start gap-6 p-3 text-center text-base md:flex">
            <div className="flex flex-row items-center justify-center overflow-hidden py-1.5">
              <Link className="relative cursor-pointer leading-[150%] text-[hsl(var(--brand))]" href="/invitations/code" data-sentry-element="Link" data-sentry-source-file="page.tsx">
                Claim Offer
              </Link>
            </div>
            <Button className="px-6 py-3" asChild data-sentry-element="Button" data-sentry-source-file="page.tsx">
              <Link href="/signin" data-sentry-element="Link" data-sentry-source-file="page.tsx">Sign In</Link>
            </Button>
          </div>
        </Header>
        <div className="m-0 flex flex-1 flex-col items-center justify-center overflow-hidden bg-[url(/assets/campaign-bg.png)] bg-cover bg-no-repeat shadow-lg md:m-3 md:mb-0 md:rounded-xl">
          <Hero data-sentry-element="Hero" data-sentry-source-file="page.tsx" />
        </div>
        <div className="flex flex-1 flex-col items-center justify-center p-0 md:p-3 md:pt-12">
          <div className="w-full md:pb-12">
            <PromoTerms originationProduct data-sentry-element="PromoTerms" data-sentry-source-file="page.tsx" />
          </div>
          <Guide data-sentry-element="Guide" data-sentry-source-file="page.tsx" />
        </div>
        <Footer data-sentry-element="Footer" data-sentry-source-file="page.tsx" />
      </div>
    </div>;
}